import { gql } from "@apollo/client";

export const UPDATE_ADMIN = gql`
  mutation ($input: UpdateAgentProfileDTO!) {
    updateAgentProfile(input: $input) {
      message
      success
    }
  }
`;

export const DELETE_ADMIN = gql`
  mutation ($id: String!) {
    removeAdmin(id: $id) {
      message
      admin {
        _id
      }
    }
  }
`;

export const ADD_AGENT = gql`
  mutation ($input: AddAgentInput!) {
    addAgentForAgency(input: $input) {
      message
      success
      data {
        _id
        email
        status
        isCreated
      }
    }
  }
`;

export const UPDATE_AGENT_STATUS_AND_PASSWORD = gql`
  mutation ($input: AgentUpdateDTO!) {
    addAgentPasswordForAgency(input: $input) {
      message
      success
      data {
        _id
        email
        status
        isCreated
      }
    }
  }
`;

export const SEND_RESET_PASSWORD_AGENT = gql`
  mutation ($input: ResetPasswordLinkDTO!) {
    editAgentPasswordForAgency(input: $input) {
      message
      success
    }
  }
`;

export const EDIT_AGENT_DETAILS_FOR_AGENCY = gql`
  mutation ($input: AddAgentInput!) {
    editAgentDetailsForAgency(input: $input) {
      message
      success
    }
  }
`;

export const EDIT_AGENT_ACCOUNT_FOR_AGENCY = gql`
  mutation ($input: EditAccountSettingsDTO!) {
    editAgentStatusAccessForAgency(input: $input) {
      message
      success
    }
  }
`;
