import { gql } from "@apollo/client";

export const CREATE_USER_BANK_ACCOUNT = gql`
  mutation Mutation($body: UserBankAccountInput!) {
    CreateUserBankAccount(body: $body) {
      message
    }
  }
`;

export const REMOVE_USER_BANK_ACCOUNT = gql`
  mutation Mutation($input: RemoveUserBankDto!) {
    RemoveUserBankAccount(input: $input) {
      message
    }
  }
`;

export const CREATE_BECS_SETUP_INTENT = gql`
  mutation createSetupIntentForBECS($agencyId: String!) {
    createSetupIntentForBECS(agencyId: $agencyId) {
      secret
    }
  }
`;
