// assets
import agent from "./agent";
import agency from "./agency";

// ==============================|| MENU ITEMS ||============================== //

export const AgentMenuItems = {
  items: [agent],
};

export const AgencyMenuItems = {
  items: [agency],
};
