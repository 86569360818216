import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import UpgradePlanCard from "./UpgradePlanCard";

// assets
import { IconLogout, IconSettings } from "@tabler/icons";
import useConfig from "hooks/useConfig";
import { useDispatch } from "store";
import { logOut } from "store/slices/auth";
import useGQL from "hooks/useGQL";
import { clearSnackbarState } from "store/slices/snackbar";
import { AgentAgencyRoles, UserRoles } from "store/constant";
import PersonIcon from "@mui/icons-material/Person";
import ThumbNailImageComponent from "components/image/ThumbNail";
import { clearTableState } from "store/slices/table";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { borderRadius } = useConfig();
  const navigate = useNavigate();
  const { GET_AGENCY_PROFILE } = useGQL();
  const { data: agentProfile } = GET_AGENCY_PROFILE();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [agencyName, setAgencyName] = useState<string>("");
  const [agencyLogo, setAgencyLogo] = useState<string>("");

  const [open, setOpen] = useState(false);

  const { LOGOUT_AS_AGENT } = useGQL();

  const [logOutAsAnAgent] = LOGOUT_AS_AGENT();

  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any>(null);
  const handleLogout = async () => {
    try {
      await logOutAsAnAgent();
    } catch (error) {
      console.log("error", error);
    }
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("role");
    dispatch(logOut());
    dispatch(clearSnackbarState());
    dispatch(clearTableState());
    navigate("/login", { replace: true });
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number,
    route: string = ""
  ) => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (agentProfile?.getAgentProfile) {
      setAgencyName(agentProfile.getAgentProfile?.firstName);
      setAgencyLogo(
        agentProfile.getAgentProfile?.logoUrl ??
          agentProfile?.getAgentProfile?.displayImage
      );
    }
  }, [agentProfile]);

  const onManageSubscriptionClick = () => {
    setOpen(false);

    navigate("/subscription");
  };

  console.log("agentProfile", agentProfile?.getAgentProfile);

  return (
    <>
      <Chip
        sx={{
          height: "52px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          paddingRight: "4px",
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.grey[500],
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.grey[100],
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.grey[100],
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <ThumbNailImageComponent awsKey={agencyLogo} size="84x84">
            <Avatar
              // src={User1}
              // src={agencyLogo}
              sx={{
                ...theme.typography.mediumAvatar,
                margin: "8px 0 8px 8px !important",
                cursor: "pointer",
              }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              color="inherit"
            />
          </ThumbNailImageComponent>
        }
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            color={theme.palette.secondary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            boxShadow: "0px 25px 50px rgba(0, 0, 0, 0.25)",
          },
          "& .MuiCard-root": {
            padding: "24px 16px",
          },
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Stack direction="column" spacing={2}>
                      <Box>
                        <Stack>
                          <Stack
                            direction="row"
                            spacing={0.5}
                            alignItems="center"
                          >
                            <Typography variant="h4">Good Morning,</Typography>
                            <Typography
                              component="span"
                              variant="h4"
                              sx={{ fontWeight: 400 }}
                            >
                              {/* {user?.name} */}
                              {agencyName}
                            </Typography>
                          </Stack>
                          <Typography variant="subtitle2">
                            {agentProfile?.getAgentProfile?.role ===
                            UserRoles.AGENT
                              ? AgentAgencyRoles.AGENT
                              : AgentAgencyRoles.AGENCY_MANAGER}
                          </Typography>
                        </Stack>
                      </Box>

                      <Box>
                        {agentProfile?.getAgentProfile?.role ===
                          UserRoles.AGENCY ||
                        agentProfile?.getAgentProfile?.hasAgencyAccess ? (
                          <UpgradePlanCard {...{ onManageSubscriptionClick }} />
                        ) : null}
                        <Divider
                          sx={{ borderColor: theme.palette.primary[100] }}
                        />
                        <List
                          component="nav"
                          sx={{
                            mt: "11px",
                            mb: "5px",
                            //width: "100%",
                            //maxWidth: 350,
                            minWidth: 268,
                            //backgroundColor: theme.palette.background.paper,
                            //borderRadius: "10px",
                            [theme.breakpoints.down("md")]: {
                              //minWidth: "100%",
                            },
                            "& .MuiListItemButton-root": {
                              //mt: 0.5,
                            },
                          }}
                        >
                          <ListItemButton
                            color="secondary"
                            sx={{ borderRadius: `${borderRadius}px` }}
                            selected={selectedIndex === 0}
                            onClick={(
                              event: React.MouseEvent<HTMLDivElement>
                            ) => handleListItemClick(event, 0, "/profile")}
                          >
                            <ListItemIcon>
                              <PersonIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">Profile</Typography>
                              }
                            />
                          </ListItemButton>

                          <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            selected={selectedIndex === 4}
                            onClick={handleLogout}
                          >
                            <ListItemIcon>
                              <IconLogout stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">Logout</Typography>
                              }
                            />
                          </ListItemButton>
                        </List>
                      </Box>
                    </Stack>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
