import React from "react";

function DashboardIcon({ color = "#101623" }) {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2099 15.3901C19.5737 16.8946 18.5787 18.2203 17.3118 19.2514C16.0449 20.2825 14.5447 20.9875 12.9424 21.3049C11.34 21.6222 9.68434 21.5422 8.12006 21.0719C6.55578 20.6015 5.13054 19.7551 3.96893 18.6067C2.80733 17.4583 1.94473 16.0428 1.45655 14.484C0.968371 12.9252 0.869476 11.2706 1.16851 9.66471C1.46755 8.05886 2.15541 6.55071 3.17196 5.27211C4.18851 3.99351 5.5028 2.9834 6.99992 2.33008"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2388 7.67317C20.7413 8.88642 21 10.1868 21 11.5H11V1.5C12.3132 1.5 13.6136 1.75866 14.8268 2.2612C16.0401 2.76375 17.1425 3.50035 18.0711 4.42893C18.9997 5.35752 19.7362 6.45991 20.2388 7.67317Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DashboardIcon;
