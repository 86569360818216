// third-party
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// project imports
import snackbarReducer from "./slices/snackbar";
import customerReducer from "./slices/customer";
import contactReducer from "./slices/contact";
import productReducer from "./slices/product";
import chatReducer from "./slices/chat";
import calendarReducer from "./slices/calendar";
import mailReducer from "./slices/mail";
import userReducer from "./slices/user";
import cartReducer from "./slices/cart";
import kanbanReducer from "./slices/kanban";
import menuReducer from "./slices/menu";
import editorReducer from "./slices/editor";
import authReducer from "./slices/auth";
import tableReducer from "./slices/table";
import pageReducer from "./slices/page";
import formReducer from "./slices/form";
import reviewReducer from "./slices/reviews";
import checkinsReducer from "./slices/checkins";
import { taxonomyReducer } from "hooks/reducers/useTaxonomyReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  snackbar: snackbarReducer,
  cart: persistReducer(
    {
      key: "cart",
      storage,
      keyPrefix: "berry-",
    },
    cartReducer
  ),
  kanban: kanbanReducer,
  customer: customerReducer,
  contact: contactReducer,
  product: productReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  mail: mailReducer,
  user: userReducer,
  menu: menuReducer,
  editor: editorReducer,
  auth: persistReducer(
    {
      key: "auth",
      storage,
    },
    authReducer
  ),
  table: tableReducer,
  page: pageReducer,
  form: formReducer,
  taxon: taxonomyReducer,
  review: reviewReducer,
  checkins: checkinsReducer,
});

export default reducer;
