import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation ($input: LoginAdminDTO!) {
    loginAsAnAgent(input: $input) {
      agent {
        _id
        email
        status
        isDeleted
        isTemporaryPassword
        role
        agencyId
        firstName
        lastName
        agencyDetails {
          agencyName
        }
      }
      refreshToken
      accessToken
    }
  }
`;

export const REGISTER = gql`
  mutation ($input: CreateAdminDTO!) {
    register(input: $input) {
      _id
      name
      email
      status
      role
      phone
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ($input: String!) {
    agentForgotPassword(input: $input)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($input: ResetPasswordDTO!) {
    agentResetPassword(input: $input)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ($input: ChangeAgentPasswordDTO!) {
    changeAgentPassword(input: $input) {
      message
      status
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshAgent(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

export const LOGOUT = gql`
  mutation logOutAsAnAgent {
    logOutAsAnAgent {
      message
      success
    }
  }
`;
