import { gql } from "@apollo/client";

export const CREATE_CARD_PAYMENT_METHOD = gql`
  mutation Mutation($input: UserCardPaymentMethodDto!) {
    CreateUserCardPaymentMethod(input: $input) {
      message
      userCardPaymentMethod {
        name
        last4
        cvc_check
      }
    }
  }
`;

export const REMOVE_USER_CARD_PAYMENT_METHOD = gql`
  mutation Mutation($input: RemoveUserCardDto!) {
    RemoveUserCardDetails(input: $input) {
      message
    }
  }
`;

export const MAKE_USER_CARD_DEFAULT = gql`
  mutation Mutation($input: MakeDefaultUserCardDto!) {
    MakeDefaultUserCard(input: $input) {
      message
    }
  }
`;
