// material-ui
import { useTheme } from "@mui/material/styles";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
     *
     */
    <svg
      width="126"
      height="41"
      viewBox="0 0 126 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.8498 0C62.42 0 68.5569 6.13685 68.5569 13.707V27.293C68.5569 34.8632 62.42 41 54.8498 41C47.2797 41 41.1428 34.8631 41.1428 27.293V13.707C41.1428 6.13685 47.2797 0 54.8498 0ZM62.5915 17.8869L57.3816 18.4341C57.1674 17.8201 56.8861 17.4019 56.5379 17.1795C56.0915 16.8948 55.4218 16.7524 54.529 16.7524C53.8325 16.7524 53.3459 16.8859 53.0691 17.1528C52.7924 17.4197 52.654 17.8869 52.654 18.5542C53.3325 18.8211 53.9754 19.0391 54.5825 19.2082C55.1897 19.3683 56.5156 19.653 58.5602 20.0623C60.2656 20.4004 61.4352 20.9165 62.0691 21.6105C62.7031 22.3045 63.02 23.1898 63.02 24.2664C63.02 25.4142 62.5825 26.3796 61.7075 27.1626C60.8236 27.9456 59.5334 28.3371 57.837 28.3371C56.5602 28.3371 55.4397 28.1413 54.4754 27.7498C53.77 27.4562 53.0602 26.9668 52.3459 26.2817C52.2834 26.691 52.2209 26.9935 52.1584 27.1893C52.0959 27.3761 51.962 27.6519 51.7566 28.0167H46.6406C46.9263 27.4295 47.1138 26.949 47.2031 26.5753C47.2834 26.1927 47.3236 25.6678 47.3236 25.0005V18.741C47.3236 18.0737 47.4575 17.3753 47.7254 16.6457C47.9933 15.9072 48.3593 15.3377 48.8236 14.9373C49.4843 14.359 50.3102 13.9808 51.3013 13.8029C52.2924 13.616 53.6049 13.5226 55.2388 13.5226C56.2566 13.5226 57.2031 13.5982 58.0781 13.7495C58.9531 13.9008 59.6406 14.1143 60.1406 14.3901C60.837 14.7727 61.3727 15.2265 61.7477 15.7514C62.1138 16.2675 62.395 16.9793 62.5915 17.8869ZM54.9977 21.9975C54.1852 21.7929 53.404 21.5571 52.654 21.2902V22.1577C52.654 22.8339 52.7656 23.3856 52.9888 23.8126C53.2031 24.2308 53.5736 24.5867 54.1004 24.8804C54.6183 25.174 55.1808 25.3208 55.7879 25.3208C56.3683 25.3208 56.8102 25.1873 57.1138 24.9204C57.4084 24.6446 57.5558 24.2931 57.5558 23.866C57.5558 23.4923 57.404 23.1587 57.1004 22.8651C56.8058 22.5803 56.1049 22.2912 54.9977 21.9975Z"
        fill="url(#paint0_linear_6364_72305)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.0053 0C74.1422 0 71.0105 3.1317 71.0105 6.99485V15.8967C71.0423 15.8467 71.0748 15.7983 71.108 15.7514C71.483 15.2265 72.0187 14.7727 72.7152 14.3901C73.2152 14.1143 73.9027 13.9008 74.7777 13.7495C75.6527 13.5982 76.5991 13.5226 77.617 13.5226C79.2509 13.5226 80.5634 13.616 81.5545 13.8029C82.5455 13.9808 83.3714 14.359 84.0321 14.9373C84.4252 15.2763 84.7479 15.7365 85.0002 16.3178V6.99485C85.0002 3.1317 81.8685 0 78.0053 0ZM85.0002 28.0167H81.0991C80.8937 27.6519 80.7598 27.3761 80.6973 27.1893C80.6348 26.9935 80.5723 26.691 80.5098 26.2817C79.7955 26.9668 79.0857 27.4562 78.3803 27.7498C77.4161 28.1413 76.2955 28.3371 75.0187 28.3371C73.3223 28.3371 72.0321 27.9456 71.1482 27.1626C71.101 27.1204 71.0551 27.0776 71.0105 27.0344V34.0052C71.0105 37.8683 74.1422 41 78.0053 41C81.8685 41 85.0002 37.8683 85.0002 34.0052V28.0167ZM71.0105 21.3921C71.6792 20.8072 72.7742 20.3639 74.2955 20.0623C76.3402 19.653 77.6661 19.3683 78.2732 19.2082C78.8803 19.0391 79.5232 18.8211 80.2018 18.5542C80.2018 17.8869 80.0634 17.4197 79.7866 17.1528C79.5098 16.8859 79.0232 16.7524 78.3268 16.7524C77.4339 16.7524 76.7643 16.8948 76.3178 17.1795C75.9696 17.4019 75.6884 17.8201 75.4741 18.4341L71.0105 17.9652V21.3921ZM77.858 21.9975C78.6705 21.7929 79.4518 21.5571 80.2018 21.2902V22.1577C80.2018 22.8339 80.0902 23.3856 79.867 23.8126C79.6527 24.2308 79.2821 24.5867 78.7553 24.8804C78.2375 25.174 77.675 25.3208 77.0678 25.3208C76.4875 25.3208 76.0455 25.1873 75.742 24.9204C75.4473 24.6446 75.3 24.2931 75.3 23.866C75.3 23.4923 75.4518 23.1587 75.7553 22.8651C76.05 22.5803 76.7509 22.2912 77.858 21.9975Z"
        fill="url(#paint1_linear_6364_72305)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6364_72305"
          x1="54.8498"
          y1="0"
          x2="54.8498"
          y2="41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#02399E" />
          <stop offset="1" stopColor="#6DA0F9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6364_72305"
          x1="78.0053"
          y1="0"
          x2="78.0053"
          y2="41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#02399E" />
          <stop offset="1" stopColor="#6DA0F9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Logo;
