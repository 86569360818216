import React, { MouseEvent } from "react";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useAlertDialog from "hooks/common/useAlertDialog";
import useAlertDialogContext from "hooks/common/useAlertDialogContext";

export default function AlertDialog() {
  const {
    dialog: { open, dialogConfig }
  } = useAlertDialogContext();
  const { onConfirm, onClose } = useAlertDialog();
  const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
    const dataId = event.currentTarget.getAttribute("data-id");
    if (dataId === "yes") {
      onConfirm();
    }
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogConfig.title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            component="div"
            sx={{ padding: "6px 28px 25px 28px" }}
          >
            <Typography variant="subtitle1">{dialogConfig.message}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            data-id="no"
            size="large"
            sx={{ minWidth: "70px" }}
          >
            No
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            data-id="yes"
            size="large"
            autoFocus
            sx={{ minWidth: "85px" }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
