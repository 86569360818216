// third-party
import { FormattedMessage } from "react-intl";

// assets
import PropertiesIcon from "ui-component/icons/PropertiesIcon";
import AgencyIcon from "ui-component/icons/AgencyIcon";
import DashboardIcon from "ui-component/icons/statsIcon";

// constant
const icons = {
  DashboardIcon,
  AgencyIcon,
  PropertiesIcon,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: "sample-docs-roadmap",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      icon: icons.DashboardIcon,
      url: "/dashboard",
      breadcrumbs: false,
    },
    {
      id: "agency",
      title: <FormattedMessage id="agency" defaultMessage="My Agency" />,
      type: "item",
      hasChildren: true,
      icon: icons.AgencyIcon,
      url: "/agency",
      breadcrumbs: false,
    },
    {
      id: "property",
      title: "Properties",
      type: "item",
      icon: icons.PropertiesIcon,
      url: "/property/list",
      breadcrumbs: false,
    },
  ],
};

export default other;
