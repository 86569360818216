import React from "react";

function PieIcon({ color = "#101623" }) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4142 14.9142C18.7893 14.5391 19 14.0304 19 13.5V3.5C19 2.96957 18.7893 2.46086 18.4142 2.08579C18.0391 1.71071 17.5304 1.5 17 1.5H3C2.46957 1.5 1.96086 1.71071 1.58579 2.08579C1.21071 2.46086 1 2.96957 1 3.5V19.5L5 15.5H17C17.5304 15.5 18.0391 15.2893 18.4142 14.9142Z"
        fill="#F5F5F5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PieIcon;
