// project imports
import { Theme } from "@mui/material/styles";

export default function componentStyleOverrides(
  theme: Theme,
  borderRadius: number,
  outlinedFilled: boolean
) {
  const mode = theme.palette.mode;
  const bgColor =
    mode === "dark" ? theme.palette.dark[800] : theme.palette.grey[100];
  const menuSelectedBack =
    mode === "dark"
      ? theme.palette.secondary.main + 15
      : theme.palette.primary.light;
  const menuSelected =
    mode === "dark" ? theme.palette.secondary.main : theme.palette.primary.main;

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: "24px",
          boxShadow: "none",
          "&.MuiButton-containedSecondary": {
            "&:hover": {
              //backgroundColor: `${theme.palette.secondary.light}!important`,
            },
          },
          "&.MuiButton-containedPrimary": {
            ":hover": {
              borderColor: `${theme.palette.primary.main}!important`,
            },
          },
          "&.MuiButton-text": {
            color: theme.palette.grey[600],
            "&.MuiButton-textPrimary": {
              color: theme.palette.primary.main,

              "&.MuiButton-sizeSmall": {
                padding: "0 4px",
                minWidth: "auto",
                "&:hover, &.Mui-selected": {
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary.light,
                },
              },
            },
            "&.MuiButton-textSecondary": {
              fontWeight: "normal",
              color: theme.palette.secondary.main,
              "&:hover": {
                color: theme.palette.primary.main,
                backgroundColor: "transparent",
              },
              "&.Mui-disabled": {
                opacity: "0.3",
              },
            },
            "&.MuiButton-textError": {
              color: theme.palette.error.dark,
              padding: "0",
              "&:hover": {
                backgroundColor: "transparent",
                color: theme.palette.error.dark,
              },
            },

            "& .MuiButton-startIcon": {
              marginRight: "12px!important",
            },
            "&:hover": {
              color: theme.palette.secondary.main,
            },
            "&.MuiButton-sizeMedium": {
              padding: "0 4px",
              minWidth: "auto",
              lineHeight: 1,
            },
          },
          "&.MuiButton-sizeMedium": {
            padding: "7px 19px",
          },
          "&.MuiButton-sizeLarge": {
            borderRadius: "32px",
            padding: "8px 16px",
          },

          "&.MuiButton-outlinedPrimary": {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
          },
          "&.MuiButton-contained": {
            "&.MuiButton-colorInherit": {
              backgroundColor: theme.palette.secondary[200],
              color: theme.palette.secondary.main,
            },
          },
          "&.MuiButton-outlinedInherit": {
            borderColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.MuiIconButton-colorInherit": {
            color: theme.palette.secondary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.light,
            },
          },
          "&.MuiIconButton-colorSecondary": {
            backgroundColor: theme.palette.grey[100],
            "&:hover": {
              backgroundColor: theme.palette.primary.light,
            },
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          ".MuiAlert-root": {
            display: "flex",
            padding: "6px 16px",
            alignItems: "center",
            "& .MuiAlert-message": {
              color: "white",
            },
            "& .MuiSvgIcon-root": {
              color: "white",
            },
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          padding: "13px 20px 16px 40px",
          margin: "0 0 24px",
          display: "block",
          "&.MuiAlert-standardSuccess": {
            backgroundColor: theme.palette.success.light,
            boxShadow: `10px 0px 0px 0px ${theme.palette.success.dark} inset`,
            "& .MuiAlert-message": {
              color: theme.palette.success.dark,
            },
          },
          "&.MuiAlert-standardError": {
            backgroundColor: theme.palette.error.light,
            boxShadow: `10px 0px 0px 0px ${theme.palette.error.dark} inset`,
            "& .MuiAlert-message": {
              color: theme.palette.error.dark,
            },
          },
          "& .MuiAlert-message": {
            //padding: "0 0 0 20px",
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          //backgroundColor: theme.palette.background.paper,
          backgroundImage: "none",
          "&.MuiPaper-outlined:not(.customTable)": {
            //borderColor: theme.palette.grey[500],
            borderColor: theme.palette.primary[100],
            "& .MuiCardHeader-root": {
              padding: "6px 14px",
              borderBottom: "1px solid",
              borderColor: theme.palette.primary[100],
              "& .MuiCardHeader-title": {
                fontSize: "0.75rem",
              },
            },
            "& .MuiCardContent-root": {
              // padding: "24px 0",
            },
            "& .dataTable": {
              "& .MuiTableRow-root": {
                "& .MuiTableCell-head": {
                  padding: "9px 15px",
                  fontSize: "0.75rem",
                  color: theme.palette.grey[500],
                },
                "& .MuiTableCell-root": {
                  fontWeight: "normal",
                  borderBottom: `1px solid ${theme.palette.secondary.light}`,
                },
                "&:last-of-type": {
                  "& .MuiTableCell-root": {
                    borderBottom: "none",
                  },
                },
              },
            },
          },
          "&.MuiPaper-outlined": {
            "&.customTable": {
              border: "none",
              "& .MuiCardHeader-root": {
                display: "none",
              },
              "& .MuiTableCell-head": {
                fontSize: ".75rem",
                padding: "6px 16px 16px 4px",
              },
              "& .MuiTableCell-root": {
                padding: "16px 16px 16px 4px!important",
              },
            },
          },
          "&.MuiPaper-outlined.customInputCard": {
            borderColor: theme.palette.secondary[800],
            padding: "15px 16px 14px",
            margin: "0",
            "&:hover, &:focus-within": {
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.background.paper,
            },
          },
        },
        rounded: {
          borderRadius: `${borderRadius}px`,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          marginBottom: "24px",
          //borderColor: theme.palette.primary[100],
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "24px 20px 20px",
          borderRadius: "12px 12px 0 0",
          backgroundColor: theme.palette.background.paper,
          // borderBottom: "1px solid",
          // borderColor: theme.palette.primary[100],
          // padding: "16px 16px 12px",
          "&:has(.MuiCardHeader-subheader)": {
            padding: "19px 20px 13px",
            "& + .MuiDivider-root": {
              display: "none",
            },
          },
        },
        title: {
          // fontSize: "2.125rem",
          fontSize: "1.5rem",
          ":has(+.MuiCardHeader-subheader)": {
            fontSize: "2.125rem",
            lineHeight: 1.2,
          },
        },
        subheader: {
          fontSize: "1rem",
          fontWeight: "normal",
          marginTop: "7px",
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          "& .slick-slider": {
            "& .slick-slide, & .slick-slide div": {
              display: "block!important",
            },
            "& .slick-dots": {
              position: "absolute",
              textSlign: "center",
              bottom: "15px",
              left: "50%",
              transform: "translateX(-50%)",
              whiteSpace: "nowrap",
              zIndex: "10",
              "& li": {
                width: "12px",
                height: "12px",
                margin: "0 2px",
                "& button": {
                  width: "10px",
                  height: "10px",
                  padding: "0",
                  "&::before": {
                    width: "10px",
                    height: "10px",
                    fontSize: "10px",
                    lineHeight: "1",
                    color: "white",
                    opacity: ".9",
                    transform: "scale(0.9)",
                  },
                  fontSize: "1px",
                  color: "white",
                },
                "&.slick-active": {
                  "& button::before": {
                    opacity: "1",
                    color: "white",
                    transform: "scale(1)",
                  },
                },
              },
            },
            "& .slick-arrow": {
              zIndex: "2",
              width: "20px",
              height: "37px",
              "&.slick-prev": {
                left: "15px",
                "&::before": {
                  content: "url(/icons/prevIcon.svg)",
                },
              },
              "&.slick-next": {
                right: "15px",
                "&::before": {
                  content: "url(/icons/nextIcon.svg)",
                },
              },
            },
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px 0",
          // borderRadius: '12px',

          "& .tox-tinymce": {
            borderColor: theme.palette.grey[500],
            borderWidth: "1px",
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    // MuiAlert: {
    //   styleOverrides: {
    //     root: {
    //       alignItems: "center",
    //     },
    //     outlined: {
    //       border: "1px dashed",
    //     },
    //   },
    // },
    MuiList: {
      styleOverrides: {
        root: {
          "& .MuiListItemButton-root": {
            marginBottom: "7px",
            paddingLeft: "13px",
            "& .MuiSvgIcon-root": {
              // fontSize: "2rem",
              // width: "22px",
              // height: "22px",
            },
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.MuiListItem-dense": {
            "& .MuiListItemButton-root": {
              padding: "0",
              margin: "0",
            },
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
          paddingTop: "10px",
          paddingBottom: "10px",
          // backgroundColor: bgColor,
          "&.Mui-selected": {
            color: menuSelected,
            backgroundColor: menuSelectedBack,
            "&:hover": {
              backgroundColor: menuSelectedBack,
            },
            "& .MuiListItemIcon-root": {
              color: theme.palette.secondary.main,
              //color: menuSelected,
            },
          },
          "&:hover": {
            backgroundColor: menuSelectedBack,
            color: menuSelected,
            "& .MuiListItemIcon-root": {
              color: theme.palette.secondary.main,
              //color: menuSelected,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.main,
          minWidth: "36px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.palette.text.dark,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.palette.text.dark,
          "&::placeholder": {
            color: theme.palette.text.secondary,
            fontSize: "0.875rem",
          },
          "&:-webkit-autofill": {
            borderRadius: "12px",
            WebkitBoxShadow: "0 0 0 1000px #fff inset",
            WebkitTextFillColor: theme.palette.grey[900],
          },
        },
        root: {
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: "0",
          "&.MuiFormControlLabel-labelPlacementEnd": {
            marginLeft: "3px",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-filled": {
            transform: "translate(16px, 22px) scale(1)",
            "&.MuiInputLabel-shrink": {
              transform: "translate(16px, 15px) scale(0.75)",
            },
          },

          "& .MuiInputLabel-sizeSmall": {
            transform: "translate(14px, 12px) scale(1)",
            "&.MuiInputLabel-shrink": {
              transform: "translate(14px, -8px) scale(0.75)",
            },
          },
          "&:hover": {
            "& .MuiInputLabel-filled": {
              color: theme.palette.primary.main,
            },
            "& .Mui-disabled": {
              color: theme.palette.grey[400],
            },
          },
          "& .Mui-disabled": {
            color: theme.palette.grey[400],
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // color: theme.palette.grey[500],
          // backgroundColor: theme.palette.grey[50],
          // border: `1px solid ${theme.palette.grey[100]}`,
          // borderRadius: "12px",
          // padding: "9px 15px",
          "& .MuiInput-input": {
            //padding: "4px 0",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.secondary[800]}`,
          borderRadius: "12px",
          "& .MuiFilledInput-input": {
            padding: "30px 16px 13px",
          },
          "&:hover, &:focus-within": {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.background.paper,
          },
          "&.MuiInputBase-sizeSmall": {
            padding: "9px 15px",
            backgroundColor: theme.palette.grey[50],
            border: `1px solid ${theme.palette.grey[100]}`,
            "& .MuiInputAdornment-positionStart": {
              margin: "0 8px 0 0!important",
            },
            "& .MuiFilledInput-input": {
              padding: "4px 0",
            },
          },
          "&.MuiInputBase-adornedStart": {
            "& .MuiInputAdornment-positionStart": {
              marginLeft: "3px",
            },
            "& .MuiFilledInput-input": {
              paddingLeft: "0 !important",
            },
          },
          "&.Mui-disabled": {
            color: theme.palette.grey[400],
            borderColor: theme.palette.secondary[800],
            backgroundColor: theme.palette.grey[50],
            "& .MuiFilledInput-input": {
              "&:hover": {
                color: theme.palette.grey[400],
              },
            },
          },
          "&.MuiInputBase-multiline": {
            padding: "0",
          },
          "&:before, &:after": {
            display: "none!important",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: outlinedFilled
            ? theme.palette.background.paper
            : theme.palette.grey[50],
          borderRadius: `${borderRadius}px`,
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "12px",
            borderColor:
              mode === "dark"
                ? theme.palette.text.primary + 28
                : theme.palette.secondary.light,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
          "&.MuiInputBase-sizeSmall": {
            padding: "8px 6px",
            width: "auto",
            // margin: "0 27px",
            "& .MuiInputBase-inputSizeSmall": {
              padding: "0 32px 0 0!important",
            },
            "&.MuiInputBase-adornedStart": {
              padding: "10px 13px",
              "& .MuiOutlinedInput-notchedOutline": {
                //borderRadius: "20px",
              },
            },
          },
          "&.Mui-focused": {
            borderWidth: "1px",
            borderColor: theme.palette.primary.main,
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          },
          "&.MuiInputBase-colorPrimary": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.secondary[800],
            },
          },
          "& .MuiSelect-icon": {
            right: "11px",
          },
        },
        input: {
          fontWeight: 500,
          backgroundColor: outlinedFilled
            ? theme.palette.background.paper
            : theme.palette.grey[50],
          //padding: "15.5px 14px",
          borderRadius: `${borderRadius}px`,
          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
          // "&:-webkit-autofill": {
          //   WebkitBoxShadow: "0 0 0 1000px #fafafa inset",
          //   WebkitTextFillColor: theme.palette.grey[900],
          // },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: `${borderRadius}px`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color:
              mode === "dark"
                ? theme.palette.text.primary + 50
                : theme.palette.grey[300],
          },
        },
        mark: {
          backgroundColor: theme.palette.background.paper,
          width: "4px",
        },
        valueLabel: {
          color:
            mode === "dark"
              ? theme.palette.primary.main
              : theme.palette.primary.light,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiAutocomplete-tag": {
            backgroundColor:
              mode === "dark"
                ? theme.palette.text.primary + 20
                : theme.palette.secondary.light,
            borderRadius: 4,
            color: theme.palette.text.dark,
            ".MuiChip-deleteIcon": {
              color:
                mode === "dark"
                  ? theme.palette.text.primary + 80
                  : theme.palette.secondary[200],
            },
          },
          "& .MuiFilledInput-root": {
            padding: "0!important",
            "& .MuiFilledInput-input": {
              padding: "30px 16px 13px!important",
            },
          },
        },
        paper: {
          borderRadius: "0",
        },
        popper: {
          overflow: "hidden",
          borderRadius: "12px",
          backgroundColor: "white",
          boxShadow:
            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
          "& .MuiPaper-root": {
            // marginRight: "-27px",
            "& .MuiAutocomplete-listbox": {
              paddingRight: "27px",
              "&::-webkit-scrollbar": {
                width: "3px",
              },

              "&::-webkit-scrollbar-track": {
                backgroundColor: theme.palette.secondary[200],
              },

              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.grey[400],
                borderRadius: "20px",
                outline: "none",
              },
            },
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.divider,
          opacity: mode === "dark" ? 0.2 : 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          backgroundColor: `${theme.palette.background.paper}!important`,
          "& .MuiOutlinedInput-input": {},
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: "12px",
            //backgroundColor: `${theme.palette.background.paper}!important`,
          },
          "&:before, &:after": {
            display: "none",
          },
        },
        select: {
          borderRadius: "12px",
          "&.MuiInputBase-inputSizeSmall": {
            //padding: "14px 16px",
          },
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color:
            mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.primary.dark,
          backgroundColor:
            mode === "dark"
              ? theme.palette.text.primary
              : theme.palette.grey[200],
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: `${theme.palette.text.dark}!important`,
          fontWeight: "500",
          //backgroundColor: "transparent !important",
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
          "&.MuiChip-colorSuccess": {
            backgroundColor: theme.palette.success.light,
            "& .MuiChip-label": {
              color: theme.palette.success.dark,
            },
          },
          "&.MuiChip-colorError": {
            backgroundColor: theme.palette.error.light,
            "& .MuiChip-label": {
              color: theme.palette.error.dark,
            },
          },
          "&.MuiChip-colorInfo": {
            backgroundColor: theme.palette.info.light,
            "& .MuiChip-label": {
              color: theme.palette.info.dark,
            },
          },
          "&.MuiChip-colorWarning": {
            backgroundColor: theme.palette.warning.light,
            "& .MuiChip-label": {
              color: theme.palette.warning.dark,
            },
          },
          "& .MuiChip-label": {
            px: "6px",
          },
        },
      },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          fontSize: "16px",
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          marginTop: 14,
          marginBottom: 14,
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiInternalDateTimePickerTabs: {
      styleOverrides: {
        tabs: {
          backgroundColor:
            mode === "dark"
              ? theme.palette.dark[900]
              : theme.palette.primary.light,
          "& .MuiTabs-flexContainer": {
            borderColor:
              mode === "dark"
                ? theme.palette.text.primary + 20
                : theme.palette.primary[200],
          },
          "& .MuiTab-root": {
            color:
              mode === "dark"
                ? theme.palette.text.secondary
                : theme.palette.grey[900],
          },
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.primary.dark,
          },
          "& .Mui-selected": {
            color: theme.palette.primary.dark,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          // borderBottom: "1px solid",
          // borderColor:
          //   mode === "dark"
          //     ? theme.palette.text.primary + 20
          //     : theme.palette.grey[200],
        },
        root: {
          "&:not(.MuiTabs-vertical)": {
            minHeight: "auto",
            padding: "0 20px",
            backgroundColor: theme.palette.background.paper,
            borderRadius: "0 0 12px 12px",
            "& .MuiTab-root": {
              color: theme.palette.secondary.main,
              padding: "11px 0",
              minHeight: "auto",
              minWidth: "auto",
              marginRight: "40px",
              "& .MuiSvgIcon-root, & svg": {
                //fontSize: "1.65rem",
                marginRight: "13px",
              },
              "&.Mui-selected": {
                color: theme.palette.primary.main,
                "& svg:not(.MuiSvgIcon-root)": {
                  "&.fillIcon .fillPath": {
                    fill: theme.palette.primary.main,
                  },
                  "& path": {
                    stroke: theme.palette.primary.main,
                  },
                },
              },
            },
            "~ div[role='tabpanel']": {
              backgroundColor: theme.palette.background.paper,
              borderRadius: "12px 12px 0 0",
              padding: "27px 16px 24px",
              marginTop: "20px",
            },
          },
          "&.MuiTabs-vertical": {
            "& .MuiButtonBase-root": {
              alignItems: "flex-start",
              textAlign: "left",
              minHeight: "auto",
              // justifyContent: "flex-start",
              borderRadius: "15px",
              "& svg": {
                marginRight: "12px",
                marginTop: "4px",
              },
              "&.Mui-selected": {
                color: theme.palette.secondary.main,
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.dark.main
                    : theme.palette.primary.light,
                "& path": {
                  fill: theme.palette.primary.main,
                },
              },
            },
            "& .MuiTabs-indicator": {
              display: "none",
            },

            // "& button > svg": {
            //   marginBottom: "0px !important",
            //   marginRight: 1.25,
            //   marginTop: 1.25,
            //   height: 20,
            //   width: 20,
            // },
            // "& button > div > span": {
            //   display: "block",
            // },
            // "& > div > span": {
            //   display: "none",
            // },
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          padding: "4px 0",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          border: "none",
          color: theme.palette.secondary.main,
          // borderColor:
          //   mode === "dark"
          //     ? theme.palette.text.primary + 15
          //     : theme.palette.grey[200],
          "&.MuiTableCell-head": {
            fontSize: "0.875rem",
            color: theme.palette.secondary.main,
            fontWeight: 500,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: theme.palette.grey[100],
            "&:hover": {
              backgroundColor: theme.palette.primary.light,
            },
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-actions": {
            "& .MuiButtonBase-root:not(.Mui-disabled)": {
              color: theme.palette.secondary.main,
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.palette.background.paper,
          backgroundColor: theme.palette.text.secondary,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "&.customDialog": {
            "& .MuiDialog-paper": {
              width: "100%",
              maxWidth: "378px",
              border: "1px solid",
              borderColor: theme.palette.primary[100],
            },
          },
          "& .propertyReviews": {
            paddingTop: "0px",
            "& > .MuiStack-root": {
              gap: "8px 14px",
            },
            "& .review": {
              minWidth: "260px",
              border: "1px solid #E3EDFA",
              boxShadow: "0px -2px 30px rgba(0, 0, 0, 0.07)",
              borderRadius: "12px",
              padding: "18px 16px 16px!important",
              "& .MuiBox-root": {
                marginTop: "11px!important",
              },
              "& .MuiTypography-h2": {
                fontSize: "0.875rem",
              },
              "& .MuiTypography-h4": {
                fontSize: "0.813rem",
                marginTop: "0!important",
              },
              "& .MuiTypography-body1": {
                fontSize: "0.75rem",
                lineHeight: "1.1rem",
                marginTop: "0px!important",
              },
              "& .ratings": {
                marginTop: "2px!important",
                marginBottom: "10px",
                "& > *": {
                  marginTop: "7px!important",
                },
              },
            },
          },
          "& + .MuiAutocomplete-popper": {
            // opacity: '1',
            // height: 'auto'
          },
        },
        paper: {
          //padding: "19px 0 27px",
          borderRadius: "0",
          boxShadow: "0px 25px 50px rgba(0, 0, 0, 0.25)",
          '& .MuiAutocomplete-popper': {
            left: '13px!important',
            right: '0',
            width: 'calc(100% - 27px)!important',
            backgroundColor: 'transparent',
            borderRadius: `${borderRadius}px`,
          }, 
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          fontSize: "1.125rem",
          // padding: "0",
          // margin: "0 0 14px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: "16px",
          //padding: "29px 28px 2px 28px",
          padding: "18px 28px 2px 28px",
          marginTop: "2px",
          "& .MuiTypography-h3": {
            fontSize: "1.125rem",
            color: theme.palette.grey[900],
            fontWeight: "700",
            marginTop: "10px",
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          //padding: "0 0 56px",
          padding: "4px 0px 0!important",
          "& .MuiInputLabel-root": {
            color: theme.palette.grey[900],
            '&[data-shrink="true"]': {
              color: theme.palette.grey[900],
              transform: "translate(17px, 16px) scale(0.75)",
              "&.Mui-focused": {
                color: theme.palette.grey[900],
              },
            },
          },
          "& .MuiFilledInput-root": {
            borderRadius: "12px",
            borderColor: "#E7E8EC",
            "& .MuiFilledInput-input": {
              "&.MuiInputBase-inputSizeLarge": {
                padding: "32px 15px 12px",
              },
              "&::placeholder": {
                color: theme.palette.grey[900],
              },
            },
            "&.Mui-focused": {
              borderWidth: "1px!important",
            },
          },
          "& .MuiDialogContentText-root": {
            fontSize: ".75rem",
            lineHeight: 1.2,
            marginBottom: "12px",
          },
          "& .MuiList-root": {
            //width: "100%",
            maxHeight: "444px",
            overflowY: "auto",
            marginRight: "-27px",
            paddingRight: "27px",
            "& .MuiListItem-root": {
              "& .MuiListItemButton-root": {
                "&:hover": {
                  backgroundColor: theme.palette.secondary[200],
                },
                "& .MuiListItemText-root": {
                  paddingLeft: "4px",
                  "& .MuiTypography-root": {
                    fontSize: "1rem!important",
                  },
                },
                "&::-webkit-scrollbar": {
                  width: "3px",
                },

                "&::-webkit-scrollbar-track": {
                  backgroundColor: theme.palette.secondary[200],
                },

                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.grey[400],
                  borderRadius: "20px",
                  outline: "none",
                },
              },
            },
          },

          "&::-webkit-scrollbar": {
            width: "8px",
          },

          "&::-webkit-scrollbar-track": {
            backgroundColor: theme.palette.secondary[200],
          },

          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.grey[400],
            borderRadius: "20px",
            outline: "none",
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: "1px solid",
          borderColor: theme.palette.secondary.light,
          //padding: "14px 0 12px",
          padding: "31px 0 27px",
          margin: "15px 27px 0",
          display: "flex",
          gap: "8px",
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          //margin: "0 16px 20px 0!important",
          "& .MuiPagination-ul": {
            "& li": {
              //height: "32px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#FAFAFA",
              "&:first-of-type": {
                borderTopLeftRadius: "12px",
                borderBottomLeftRadius: "12px",
                paddingLeft: "8px",
              },
              "&:last-of-type": {
                borderTopRightRadius: `${borderRadius}px`,
                borderBottomRightRadius: `${borderRadius}px`,
                paddingRight: "8px",
              },
              '& button[aria-label$="page 1"], & button[aria-label$="page 2"], & button[aria-label$="page 3"], & button[aria-label$="page 4"], & button[aria-label$="page 5"],& button[aria-label$="page 6"], & button[aria-label$="page 7"], & button[aria-label$="page 8"], & button[aria-label$="page 9"]':
                {
                  "&:before": {
                    content: '"0"',
                  },
                },
            },
          },
          "& .MuiPaginationItem-root": {
            margin: "12px 8px 12px 4px",
            minWidth: "22px",
            width: "22px",
            height: "22px",
            padding: "0px",
            fontSize: ".75rem",
            fontWeight: "500",
            color: theme.palette.secondary.main,
            borderRadius: `${borderRadius / 3}px`,
            "&.Mui-selected": {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.background.paper,
              "&:hover": {
                backgroundColor: theme.palette.primary.light,
              },
            },
          },
          "& .MuiPaginationItem-previousNext": {
            borderRadius: "50%",
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.grey[600],
            width: "24px",
            height: "24px",
            marginRight: "4px",
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          borderRadius: "8px",
          marginBottom: "20px",
          padding: "10px 16px",
          "& .MuiLink-root": {
            //color: theme.palette.grey[900],
          },
          "& .MuiBreadcrumbs-separator": {
            marginLeft: "6px",
            marginRight: "6px",
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          minWidth: "24px",
          minHeight: "24px",
          boxShadow: "none",
          "&.MuiFab-secondary": {
            backgroundColor: "#e6f0fc!important",
            "& .MuiSvgIcon-root": {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "& .rdrCalendarWrapper": {
            "& .rdrWeekDay": {
              fontSize: "13px",
              fontWeight: "500",
              color: "#2B2929",
            },
            "& .rdrMonthAndYearPickers select": {
              border: "1px solid #DBDCDC",
              borderRadius: "0",
              padding: "11px 30px 11px 12px",
            },
            "& .rdrDay": {
              height: "2.5rem",
              "&.rdrDayActive": {
                backgroundColor: "#A1A8B0",
              },
              "&:not(.rdrDayPassive)": {
                "& span": {
                  color: "#DBDCDC!important",
                  "& span": {
                    color: "#2B2929!important",
                  },
                },
              },
              "&.rdrDayToday": {
                backgroundColor: "#DBDCDC",
                color: "#2B2929",
                "& .rdrDayNumber": {
                  "& span": {
                    fontWeight: "700",
                  },
                },
              },
              "&.rdrDayHovered": {
                borderRadius: "0!important",
              },
              "& *": {
                borderRadius: "0!important",
              },
              "& .rdrDayNumber": {
                fontSize: "13px",
                color: "#2B2929",
                "&:hover": {
                  borderRadius: "0",
                },
                "&::after": {
                  display: "none",
                  borderRadius: "0",
                },
                "& span": {
                  "&::after": {
                    display: "none",
                  },
                },
              },
              "& .rdrSelected": {
                left: "0",
                top: "0",
                bottom: "0",
                right: "0",
                borderRadius: "0",
                "& ~ .rdrDayNumber": {
                  "& span": {
                    fontWeight: "700",
                  },
                },
              },
              "& .rdrDayStartPreview,& .rdrDayEndPreview": {
                left: "0",
                top: "0",
                bottom: "0",
                right: "0",
              },
              "&.rdrDayDisabled": {
                //backgroundColor: "#A1A8B0",
              },
            },
          },
        },
      },
    },
  };
}
