import { gql } from "@apollo/client";

export const GET_ADMIN_LIST = gql`
  query ($input: GetAdminListDTO!) {
    getAdminList(input: $input) {
      message
      adminList {
        _id
        name
        email
        phone
        status
        role
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const GET_ADMIN = gql`
  query ($id: String!) {
    getAdmin(id: $id) {
      message
      admin {
        _id
        name
        email
        role
        status
      }
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query {
    getAgentProfile {
      _id
      firstName
      lastName
      email
      phoneNumber
      displayImage
      role
      companyName
      tradingName
      licenseNumber
      ABN
      ACN
      isCompany
      address {
        displayAddress
        suburb
        city
        postalCode
        state
      }
      numberOfAgents
      createdAt

      logoUrl
      coverPhoto
      officeLicenseNumber
      propertiesPosted
      propertiesSold
      licenseesInCharge {
        licenseInCharge
        officeLicenseNumber
      }
      hasAgencyAccess
      agencyDetails {
        _id
        firstName
        lastName
        email
        phoneNumber
        displayImage
        role
        companyName
        tradingName
        licenseNumber
        ABN
        ACN
        isCompany
        address {
          displayAddress
          suburb
          city
          postalCode
          state
        }
        numberOfAgents

        logoUrl
        coverPhoto
        officeLicenseNumber
        propertiesPosted
        propertiesSold
        licenseesInCharge {
          licenseInCharge
          officeLicenseNumber
        }
        hasAgencyAccess
        createdAt
      }
    }
  }
`;

export const GET_AGENTLIST_AGENCY = gql`
  query ($input: GetListDTO!) {
    listAgentInAgency(input: $input) {
      data {
        _id
        firstName
        lastName
        agentImage
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const VIEW_AGENT_PROFILE_FOR_AGENCY = gql`
  query ($input: ViewAgentForAgencyDTO!) {
    viewAgentDetailsForAgency(input: $input) {
      _id
      firstName
      lastName
      phoneNumber
      email
      licenseNumber
      hasAgencyAccess
      status
      agentImage
    }
  }
`;

export const GET_AGENCY_DETAIL_FOR_AGENT = gql`
  query getAgencyDetailsForAgent {
    getAgencyDetailsForAgent {
      _id
      firstName
      lastName
      email
      phoneNumber
      displayImage
      role
      companyName
      tradingName
      licenseNumber
      ABN
      ACN
      isCompany
      address {
        displayAddress
        suburb
        city
        postalCode
        state
      }
      numberOfAgents
      logoUrl
      coverPhoto
      officeLicenseNumber
      propertiesPosted
      propertiesSold
    }
  }
`;
