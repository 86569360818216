import { gql } from "@apollo/client";

export const CREATE_PAYMENT_INVOICE_INTENT = gql`
  mutation ($input: CreatePaymentIntentInputDTO!) {
    createPaymentInvoiceIntent(input: $input)
  }
`;

export const CREATE_PAYMENT_INVOICE = gql`
  mutation ($input: CreatePaymentInvoiceDTO!) {
    createPaymentInvoice(input: $input)
  }
`;
