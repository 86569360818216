// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export enum SubscriptionPricesTitle {
  RESIDENTIAL = "Residential Pricing",
  COMMERCIAL = "Commercial Pricing",
  RELISTING = "Relisting Pricing",
}

export const MIN_TABLE_WIDTH = 750;

export const BASE_SUBSCRIPTION = "BASE Subscription APBR";

export const SUBSCRIPTION_APBR = "Base ABPR Plan";

export enum SubscriptionStatus {
  ACTIVE = "active",
  CANCELED = "canceled",
}

export enum SnackbarAlertyType {
  SUCCESS = "success",
  ERROR = "error",
}

export enum PropertyListingType {
  FULL = "full",
  EARLYBIRD = "earlyBird",
}

export enum PriceType {
  RANGE = "range",
  PRICE = "price",
}

export enum PropertyCategory {
  RESIDENTIAL = "residential",
  COMMERCIAL = "commercial",
  HOLIDAY_LETTING = "holidayLetting",
}

export enum SaleType {
  SALE = "sale",
  RENT = "rent",
  SOLD = "sold",
}

export const MAXIMUM_NUM_IMAGES = 30;
export const MINMUM_NUM_IMAGES = 1;

export enum UserRoles {
  AGENCY = "agency",
  AGENT = "agent",
}

export const MAX_IMAGE_SIZE_FULL_LISTING = 5000000;

//2MB
export const MAX_IMAGE_SIZE = 2048000;

export enum PropertyListingHeaders {
  EARLY_BIRD = "Early Bird",
  FULL_LISTING = "full-listing",
}

export enum SaleTypes {
  FOR_SALE = "Buy",
  FOR_RENT = "Rent",
  SOLD = "Sold",
}

export enum PropertyCategoryConstants {
  RESIDENTIAL = "Residential",
  COMMERCIAL = "Commercial",
  HOLIDAY_LETTING = "Holiday Letting",
}

export const ALL_PROPERTY_TYPES = "All";

export interface IPropertyType {
  _id: string;
  name: string;
}

export enum PriceRange {
  MIN = 0,
  MAX = 100000,
}

export enum RentPriceRange {
  MIN = 50,
  MAX = 50000,
}

export enum SalePriceRange {
  MIN = 5000,
  MAX = 1000000000,
}

export const BED_BATH_POOL = "Any";

export enum PropertyStatus {
  SOLD = "sold",
  UNDER_OFFER = "underOffer",
  LEASED = "leased",
  UNDER_CONTRACT = "underContract",
  LIVE = "live",
  REACTIVATE = "reactivate",
  WITHDRAW = "withdraw",
}

export enum PropertyStatusLabels {
  SOLD = "Sold",
  UNDER_OFFER = "Under offer",
  LEASED = "Leased",
  UNDER_CONTRACT = "Under contract",
  LIVE = "Live",
  REACTIVATE = "Reactivate to be available",
  WITHDRAW = "Withdrawn",
}

export const MONTH_ABBREVIATION = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export enum Property {
  "commercial" = "commercial",
  "residential" = "residential",
  "holidayLetting" = "holidayLetting",
}

export const STARTING = "STARTING";
export const ENDING = "ENDING";

export const SELECTED_COUNTRY = "AU";

export enum EnquirySortOptions {
  A_Z = "A-Z",
  Z_A = "Z-A",
  LATEST_TO_OLDEST = "Newest - Oldest Users",
  OLDEST_LATEST = "Oldest - Newest Users",
}

export enum EnquirySortByEnum {
  "NEW_OLD_USERS" = "NEW_OLD_USERS",
  "OLD_NEW_USERS" = "OLD_NEW_USERS",
  "A_Z" = "lowerName",
  "Z_A" = "lowerName",
}

export enum EnquirySortOrderEnum {
  "asc" = "asc",
  "desc" = "desc",
}

export const ENQUIRY_OPTIONS = [
  "All",
  "Shedule an Inspection",
  "Price Information",
  "I'd like to register for an auction",
  "I'd like to make an offer",
  "Request copy of contract",
  "I am looking to sell,please contact me",
  "Finding similar properties",
  "Other",
];

export enum AgencyAgentStatus {
  active = "active",
  passive = "passive",
  deleted = "deleted",
  incomplete = "incomplete",
}

export const GST_PERCENTAGE = 11;

export enum SetupIntentStatus {
  SUCCEEDED = '"succeeded"',
}

export enum AgentAgencyRoles {
  AGENT = "Agent",
  AGENCY_MANAGER = "Agency Manager",
}

export enum CommercialRentPriceRange {
  MIN = 50,
  MAX = 10000000,
}
