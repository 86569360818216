import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  FORGOT_PASSWORD,
  LOGIN,
  REGISTER,
  RESET_PASSWORD,
  GET_ADMIN_LIST,
  GET_ADMIN as ADMIN,
  UPDATE_ADMIN as ADMIN_UPDATE,
  DELETE_ADMIN as ADMIN_DELETE,
  GET_USER_PROFILE,
  CHANGE_PASSWORD,
  CREATE_PAGE as CREATE_PG,
  UPDATE_PAGE as UPDATE_PG,
  DELETE_PAGE as DELETE_PG,
  LIST_PAGE as GET_LIST_PAGE,
  LIST_PAGES as GET_LIST_PAGES,
  CREATE_MENU as CREATE_MENUS,
  LIST_MENUS as GET_LIST_MENUS,
  DELETE_MENU as MENU_DELETE,
  UPDATE_MENU as MENU_UPDATE,
  LIST_MENU as GET_LIST_MENU,
  CREATE_TAXONOMY as CREATE_TAXONOMYS,
  UPDATE_TAXONOMY as TAXONOMY_UPDATE,
  DELETE_TAXONOMY as TAXONOMY_DELETE,
  LIST_TAXONOMY as GET_LIST_TAXONOMY,
  LIST_TAXONOMIES as GET_TAXONOMIES_LIST,
  GET_AGENTLIST_AGENCY,
  ADD_AGENT,
  UPDATE_AGENT_STATUS_AND_PASSWORD,
  VIEW_AGENT_PROFILE_FOR_AGENCY,
  SEND_RESET_PASSWORD_AGENT,
  EDIT_AGENT_DETAILS_FOR_AGENCY,
  EDIT_AGENT_ACCOUNT_FOR_AGENCY,
  UPLOAD_AWS,
  UPDATE_AGENT_IMAGE,
  UPDATE_AGENCY_PROFILE_IMAGE,
  CREATE_CARD_PAYMENT_METHOD,
  GET_ALL_USER_CARD_PAYMENT_METHOD,
  REMOVE_USER_CARD_PAYMENT_METHOD,
  MAKE_USER_CARD_DEFAULT,
  GET_AGENCY_DETAIL_FOR_AGENT,
  LOGOUT,
} from "../grapqhl";
import {
  CREATE_BECS_SETUP_INTENT,
  CREATE_USER_BANK_ACCOUNT,
  REMOVE_USER_BANK_ACCOUNT,
} from "grapqhl/userBankAccountPaymentMethod/mutations";
import { GET_USER_BANK_ACCOUNT } from "grapqhl/userBankAccountPaymentMethod/queries";
import {
  GET_SUBSCRIPTION_DETAIL,
  GET_SUB_PROPERTY_DUE,
} from "grapqhl/subscription/queries";
import { GET_CHARGES_LIST } from "grapqhl/property-list-charges/queries";
import {
  CREATE_PAYMENT_INVOICE,
  CREATE_PAYMENT_INVOICE_INTENT,
} from "grapqhl/payment-invoice/mutation";
import { InvoicePaymentIntentResponse } from "views/profile/subscription/billing/constants/variables";
import {
  CANCEL_SUBSCRIPTION,
  PAY_PENDING_INVOICE,
  RESTORE_SUBSCRIPTION,
} from "grapqhl/subscription/mutation";
import {
  GET_PROPERTY_DETAILS,
  GET_PROPERTY_LIST,
} from "grapqhl/property/queries";

type GetAdminListDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetPagesDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetMenusDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetListDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetTaxonsDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
  taxonomyId: string | undefined;
};

type GetTaxonByTaxonomyIdDTO = {
  taxonomyId: string | undefined;
};

type GetAgenctListForAgencyDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetAgentDetailsForAgencyDTO = {
  agentId?: string;
};

const useGQL = () => {
  const ADMIN_LOGIN = () => useMutation(LOGIN);
  const ADMIN_REGISTER = () => useMutation(REGISTER);
  const FORGOT_PASS = () => useMutation(FORGOT_PASSWORD);
  const RESET_PASS = () => useMutation(RESET_PASSWORD);
  const ADMIN_CHANGE_PASSWORD = () => useMutation(CHANGE_PASSWORD);

  /* Upload to AWS */
  const UPLOAD_TO_AWS = () => useMutation(UPLOAD_AWS);
  const AGENT_IMAGE_UPDATE = () => useMutation(UPDATE_AGENT_IMAGE);
  const UPLOAD_PROFILE_IMAGE_AGENCY = () =>
    useMutation(UPDATE_AGENCY_PROFILE_IMAGE, {
      refetchQueries: [GET_USER_PROFILE],
    });

  /* agents */
  const REGISTER_AGENT = () => useMutation(ADD_AGENT);

  const UPDATE_AGENT_PASSWORD_STATUS = () =>
    useMutation(UPDATE_AGENT_STATUS_AND_PASSWORD);

  const SEND_RESET_LINK_AGENT = () => useMutation(SEND_RESET_PASSWORD_AGENT);
  const EDIT_AGENT_DETAILS_IN_AGENCY = () =>
    useMutation(EDIT_AGENT_DETAILS_FOR_AGENCY);
  const EDIT_AGENT_SETTINGS_IN_AGENCY = () =>
    useMutation(EDIT_AGENT_ACCOUNT_FOR_AGENCY);

  /* admins */
  const GET_ALL_ADMINS = (input: GetAdminListDTO = {}) =>
    useQuery(GET_ADMIN_LIST, { variables: { input } });
  const GET_ADMIN = (id: String) => useQuery(ADMIN, { variables: { id } });
  const UPDATE_ADMIN = () => useMutation(ADMIN_UPDATE);
  const DELETE_ADMIN = () => useMutation(ADMIN_DELETE);
  const GET_AGENCY_PROFILE = () =>
    useQuery(GET_USER_PROFILE, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    });

  const GET_AGENTS_AGENCY = (input: GetAgenctListForAgencyDTO = {}) =>
    useQuery(GET_AGENTLIST_AGENCY, { variables: { input } });

  const GET_AGENT_PROFILE_FOR_AGENCY = (
    input: GetAgentDetailsForAgencyDTO = {}
  ) => useQuery(VIEW_AGENT_PROFILE_FOR_AGENCY, { variables: { input } });

  const CREATE_PAGE = () => useMutation(CREATE_PG);
  const UPDATE_PAGE = () => useMutation(UPDATE_PG);
  const DELETE_PAGE = () => useMutation(DELETE_PG);
  const LIST_PAGES = (input: GetPagesDTO = {}) =>
    useQuery(GET_LIST_PAGES, {
      variables: { input },
      notifyOnNetworkStatusChange: true,
    });
  const LIST_PAGE = (id: String) =>
    useQuery(GET_LIST_PAGE, { variables: { id } });

  const CREATE_MENU = () => useMutation(CREATE_MENUS);
  const LIST_MENUS = (input: GetMenusDTO = {}) =>
    useQuery(GET_LIST_MENUS, {
      variables: { input },
      notifyOnNetworkStatusChange: true,
    });
  const DELETE_MENU = () => useMutation(MENU_DELETE);
  const LIST_MENU = (id: String) =>
    useQuery(GET_LIST_MENU, { variables: { id } });
  const UPDATE_MENU = () => useMutation(MENU_UPDATE);

  const CREATE_TAXONOMY = () => useMutation(CREATE_TAXONOMYS);
  const UPDATE_TAXONOMY = () => useMutation(TAXONOMY_UPDATE);
  const DELETE_TAXONOMY = () => useMutation(TAXONOMY_DELETE);
  const LIST_TAXONOMY = (id: String | undefined) =>
    useQuery(GET_LIST_TAXONOMY, { variables: { id } });
  const LIST_TAXONOMIES = (input: GetListDTO = {}) =>
    useQuery(GET_TAXONOMIES_LIST, {
      variables: { input },
      notifyOnNetworkStatusChange: true,
    });

  const CREATE_USER_CARD_PAYMENT_METHOD = () =>
    useMutation(CREATE_CARD_PAYMENT_METHOD);

  const GET_CARD_PAYMENT_METHOD_LIST = (input = {}) =>
    useQuery(GET_ALL_USER_CARD_PAYMENT_METHOD);

  const DELETE_CARD_PAYMENT_METHOD = () =>
    useMutation(REMOVE_USER_CARD_PAYMENT_METHOD);

  const CREATE_DIRECT_DEBIT_PAYMENT_METHOD = () =>
    useMutation(CREATE_USER_BANK_ACCOUNT);

  const GET_DIRECT_DEBIT_PAYMENT_METHOD_LIST = (input = {}) =>
    useQuery(GET_USER_BANK_ACCOUNT);

  const DELETE_DIRECT_DEBIT_PAYMENT_METHOD = () =>
    useMutation(REMOVE_USER_BANK_ACCOUNT);

  const MAKE_DEFAULT_PAYMENT_METHOD = () => useMutation(MAKE_USER_CARD_DEFAULT);

  const GET_SUBSCRIPTION = () =>
    useQuery(GET_SUBSCRIPTION_DETAIL, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    });

  const GET_PROPERTY_CHARGES_LIST = () =>
    useLazyQuery(GET_CHARGES_LIST, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    });

  const CREATE_PAYMENT_INTENT = () =>
    useMutation<InvoicePaymentIntentResponse>(CREATE_PAYMENT_INVOICE_INTENT);

  const CREATE_INVOICE_PAYMENT = () => useMutation(CREATE_PAYMENT_INVOICE);

  const PAY_INVOICE = () =>
    useMutation(PAY_PENDING_INVOICE, {
      refetchQueries: [
        GET_SUB_PROPERTY_DUE,
        GET_PROPERTY_LIST,
        GET_PROPERTY_DETAILS,
      ],
    });

  const CANCEL_SUBSCRIPTIONS = () => useMutation(CANCEL_SUBSCRIPTION);

  const AGENCY_DETAIL_FOR_AGENT = () => useQuery(GET_AGENCY_DETAIL_FOR_AGENT);

  const LOGOUT_AS_AGENT = () => useMutation(LOGOUT);

  const RESTORE_AGENCY_SUBSCRIPTION = () => useMutation(RESTORE_SUBSCRIPTION);

  const CREATE_AU_BECS_SETUP_INTENT = () =>
    useMutation(CREATE_BECS_SETUP_INTENT);

  return {
    ADMIN_LOGIN,
    ADMIN_REGISTER,
    FORGOT_PASS,
    RESET_PASS,
    GET_ALL_ADMINS,
    GET_ADMIN,
    UPDATE_ADMIN,
    DELETE_ADMIN,
    GET_AGENCY_PROFILE,
    ADMIN_CHANGE_PASSWORD,
    CREATE_PAGE,
    UPDATE_PAGE,
    DELETE_PAGE,
    LIST_PAGES,
    LIST_PAGE,
    CREATE_MENU,
    LIST_MENUS,
    DELETE_MENU,
    LIST_MENU,
    UPDATE_MENU,
    CREATE_TAXONOMY,
    UPDATE_TAXONOMY,
    DELETE_TAXONOMY,
    LIST_TAXONOMY,
    LIST_TAXONOMIES,
    GET_AGENTS_AGENCY,
    REGISTER_AGENT,
    UPDATE_AGENT_PASSWORD_STATUS,
    GET_AGENT_PROFILE_FOR_AGENCY,
    SEND_RESET_LINK_AGENT,
    EDIT_AGENT_DETAILS_IN_AGENCY,
    EDIT_AGENT_SETTINGS_IN_AGENCY,
    UPLOAD_TO_AWS,
    AGENT_IMAGE_UPDATE,
    UPLOAD_PROFILE_IMAGE_AGENCY,
    CREATE_USER_CARD_PAYMENT_METHOD,
    GET_CARD_PAYMENT_METHOD_LIST,
    DELETE_CARD_PAYMENT_METHOD,
    CREATE_DIRECT_DEBIT_PAYMENT_METHOD,
    GET_DIRECT_DEBIT_PAYMENT_METHOD_LIST,
    DELETE_DIRECT_DEBIT_PAYMENT_METHOD,
    MAKE_DEFAULT_PAYMENT_METHOD,
    GET_SUBSCRIPTION,
    GET_PROPERTY_CHARGES_LIST,
    CREATE_PAYMENT_INTENT,
    CREATE_INVOICE_PAYMENT,
    PAY_INVOICE,
    CANCEL_SUBSCRIPTIONS,
    AGENCY_DETAIL_FOR_AGENT,
    LOGOUT_AS_AGENT,
    RESTORE_AGENCY_SUBSCRIPTION,
    CREATE_AU_BECS_SETUP_INTENT,
  };
};

export default useGQL;
