// third-party
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AgentType, LoginState } from "types";

type RegisterState = {
  isRegister: boolean;
};

type AuthState = LoginState & RegisterState & AgentType;

const initialState: AuthState = {
  isLoggedIn: false,
  isRegister: false,
  agent: {
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    agencyDetails: {
      agencyName: "",
    },
  },
};
const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginState(state, action: PayloadAction<LoginState>) {
      const { isLoggedIn, accessToken, refreshToken } = action.payload;
      state.isLoggedIn = isLoggedIn;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      // state = { ...action.payload };
    },
    setRegisterState(state, action: PayloadAction<RegisterState>) {
      const { isRegister } = action.payload;
      state.isRegister = isRegister;
    },
    setUserInfo(state, action: PayloadAction<AgentType>) {
      const { agent } = action.payload;
      state.agent = {
        _id: agent._id,
        firstName: agent.firstName,
        lastName: agent.lastName,
        email: agent.email,
        agencyDetails: agent.agencyDetails,
      };
    },
    getUserInfo(state) {
      return state;
    },
    logOut() {
      return initialState;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export const { setLoginState, setRegisterState, setUserInfo, logOut } =
  slice.actions;
