// material-ui
import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

// project imports
import AnimateButton from "ui-component/extended/AnimateButton";

// styles
const CardStyle = styled(Card)(({ theme }) => ({
  background:
    theme.palette.mode === "dark"
      ? theme.palette.dark[800]
      : `${theme.palette.warning.light}!important`,
  marginTop: "0",
  marginBottom: "29px",
  overflow: "hidden",
  position: "relative",
  padding: "0 19px!important",
  boxShadow: "none!important",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "19px solid ",
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.warning.main
        : theme.palette.warning.main,
    borderRadius: "50%",
    bottom: "-110px",
    right: "-140px",
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "3px solid ",
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.warning.main
        : theme.palette.warning.main,
    borderRadius: "50%",
    bottom: "-170px",
    right: "-74px",
  },
  "& .MuiCardContent-root": {
    padding: "24px 0 36px",
  },
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const UpgradePlanCard = ({
  onManageSubscriptionClick,
}: {
  onManageSubscriptionClick: () => void;
}) => {
  return (
    <CardStyle>
      <CardContent>
        <Grid container direction="column" spacing={2.5}>
          <Grid item>
            <Typography variant="h4">My subscription</Typography>
          </Grid>

          <Grid item>
            <Stack direction="row">
              <AnimateButton>
                <Button
                  variant="contained"
                  sx={{ boxShadow: "none", borderRadius: "4px" }}
                  onClick={onManageSubscriptionClick}
                  size="small"
                >
                  Manage subscription
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </CardStyle>
  );
};

export default UpgradePlanCard;
