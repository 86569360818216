import { gql } from "@apollo/client";

export const GET_ALL_USER_CARD_PAYMENT_METHOD = gql`
  query {
    GetAllUserCardPaymentMethods {
      userCardPaymentMethods {
        _id
        name
        exp_month
        last4
        exp_year
        stripeSourceId
        isDefault
        isExpired
        brand
        stripeSourceId
        stripePaymentMethodId
      }
    }
  }
`;
