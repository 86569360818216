import { gql } from "@apollo/client";

export const GET_PROPERTY_LIST = gql`
  query ($input: GetListDTO!) {
    listAllPropertyForAgency(input: $input) {
      data {
        _id
        image
        name
        agentName
        address
        price
        minPrice
        maxPrice
        saleType
        propertyType
        listingType
        status
        showPrice
        soldPrice
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const GET_PROPERTY_DETAILS = gql`
  query ($input: ViewPropertyDTO!) {
    viewPropertyDetails(input: $input) {
      _id
      name
      listingType
      type
      subSaleType
      propertyDetails {
        bed
        bath
        car
        pool
      }
      property
      propertyType
      price
      minPrice
      maxPrice
      saleType
      images
      description
      agentPriceGuideUrl
      isTenated
      isVacant
      featureWords
      lifeStyleWords
      locationImage
      address {
        displayAddress
        suburb
        country
        postalCode
        state
      }
      location
      agentDetails {
        agentId
        agentName
        agentImage
      }
      inspectionDateDetails {
        _id
        date
        startTime
        endTime
      }
      isInspectionByAppointment
      status
      soldPrice
      landSize
      buildingSize
      availableFrom
      showPrice
      isPetAllowed
      isFurnished
      councilZoning
      straNumber
      priceUnit
      auctionDate {
        date
        startTime
        endTime
      }
      videoUrl
      showAddress
      createdAt
    }
  }
`;

export const LIST_ALL_CHECKINS = gql`
  query ($input: ListCheckInsForProperties!) {
    listAllCheckIns(input: $input) {
      data {
        _id
        firstName
        lastName
        phoneNumber
        email
        checkInDateTime
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const LIST_ALL_REVIEWS = gql`
  query ($input: ListReviewsForProperties!) {
    listAllReviews(input: $input) {
      data {
        _id
        pricingEstimate
        propertyId
        buyerId
        createdAt
        reviewerFirstName
        description
        reviews {
          title
          ratings
        }
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const LIST_ALL_INSPECTIONDATES = gql`
  query ($input: ListInspectonForProperties!) {
    listAllInspectionDates(input: $input) {
      data {
        _id
        date
        startTime
        endTime
      }
      pagination {
        total
        hasNextPage
      }
      isInspectionByAppointment
    }
  }
`;

export const PROPERTY_STATS = gql`
  query ($input: ListEnqiriesForProperties!) {
    enquiriesDataForGraph(input: $input) {
      enquiriesThisMonth {
        count
        day
      }
      viewedEnquiries {
        count
        year
        month
      }
      enquired {
        count
        year
        month
      }
      visited {
        count
        year
        month
      }
    }
  }
`;

export const LIST_PROPERTY_TYPE = gql`
  query listPropertyType($input: GetPropertyTypeInput!) {
    listPropertyType(input: $input) {
      _id
      name
      type
    }
  }
`;

export const LIST_LIFESTYLE_WORDS = gql`
  query {
    listLifeStyleWords {
      _id
      name
      type
    }
  }
`;

export const LIST_FEATURE_WORDS = gql`
  query {
    listFeatureWords {
      _id
      name
      type
    }
  }
`;

export const LIST_ALL_AGENTS = gql`
  query ($input: GetListDTO!) {
    listAgentInAgency(input: $input) {
      data {
        _id
        fullName
        firstName
        lastName
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const GET_PROPERTY_LIST_FOR_AGENT = gql`
  query ($input: GetListDTO!) {
    listAllPropertyForAgent(input: $input) {
      data {
        _id
        image
        name
        agentName
        address
        price
        minPrice
        maxPrice
        saleType
        propertyType
        listingType
        status
        showPrice
        soldPrice
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const GET_MY_AGENCY_PROPERTIES = gql`
  query listAndFilterAgentProperty($input: GetAgentPropertyListDTO!) {
    listAndFilterAgentProperty(input: $input) {
      data {
        details {
          bed
          bath
          bath
          pool
          car
        }
        _id
        name
        listingType
        property
        showPrice
        type
        saleType
        price
        maxPrice
        minPrice
        subSaleType
        images
        showSoldPrice
        soldPrice
        status
        address {
          displayAddress
          suburb
        }
        propertyType
      }
      pagination {
        hasNextPage
        total
      }
    }
  }
`;

export const AGENT_MY_PROPERTIES = gql`
  query listAndFilterAllAgentProperty($input: GetAgentPropertyListDTO!) {
    listAndFilterAllAgentProperty(input: $input) {
      data {
        details {
          bed
          bath
          bath
          pool
          car
        }
        _id
        name
        listingType
        property
        showPrice
        type
        saleType
        price
        maxPrice
        minPrice
        subSaleType
        images
        showSoldPrice
        soldPrice
        status
        address {
          displayAddress
          suburb
        }
        propertyType
      }
      pagination {
        hasNextPage
        total
      }
    }
  }
`;
