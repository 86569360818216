import { memo, useEffect, useState } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { AgentMenuItems, AgencyMenuItems } from 'menu-items';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_PROFILE } from 'grapqhl';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const [hasAgencyAccess, setAgencyAcess] = useState<boolean>(false);

  const [getAgentProfile] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data?.getAgentProfile) {
        const {
          _id,
          firstName,
          lastName,
          email,
          phoneNumber,
          displayImage,
          role,
          hasAgencyAccess,
        } = data.getAgentProfile;

        hasAgencyAccess === true ? setAgencyAcess(true) : setAgencyAcess(false);
      }
    },
  });

  useEffect(() => {
    getAgentProfile();
  }, [getAgentProfile]);
  const role = localStorage.getItem('role');

  let filteredMenuItems;
  if (role === 'agency') {
    filteredMenuItems = AgencyMenuItems?.items.map((menu) => {
      return {
        ...menu,
      };
    });
  } else {
    filteredMenuItems = AgentMenuItems?.items?.map((menu) => {
      if (menu.id === 'sample-docs-roadmap' && !hasAgencyAccess) {
        return {
          ...menu,
          children: menu.children.filter((child) => child?.id !== 'myAgency'),
        };
      } else {
        return menu;
      }
    });
  }

  const navItems = filteredMenuItems?.map((item) => {
    switch (item?.type) {
      case 'group':
        return <NavGroup key={item?.id} item={item} />;
      default:
        return (
          <Typography key={item?.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default memo(MenuList);
