import { useNavigate } from 'react-router-dom';

// project imports
import { GuardProps } from 'types';
import { useEffect } from 'react';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AgencyAuthGuard = ({ children }: GuardProps) => {
    const navigate = useNavigate();
    const isValidToken: string = localStorage.getItem('refreshToken') || '';
    const isLoggedIn: string = localStorage.getItem('isLoggedIn') || '';
    const role: string = localStorage.getItem('role') || '';

    useEffect(() => {
        if (!isLoggedIn && !isValidToken) {
            navigate('/login', { replace: true });
        }
        else if( role !== 'agency' ){
            navigate('/', {replace: true})
        }
    }, [navigate]);

    return children;
};

export default AgencyAuthGuard;
