import { gql } from "@apollo/client";

export const PAY_PENDING_INVOICE = gql`
  mutation payPendingInvoice($paymentMethod: String!) {
    payPendingInvoice(paymentMethod: $paymentMethod) {
      message
      paymentInvoiceId
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($input: CancelSubscriptionInputDTO!) {
    cancelSubscription(input: $input)
  }
`;

export const RESTORE_SUBSCRIPTION = gql`
  mutation restoreSubscription {
    restoreSubscription {
      message
    }
  }
`;
