import { gql } from "@apollo/client";

export const GET_CHARGES_LIST = gql`
  query getPropertyChargesListForAgency($input: GetChargeListInputDTO!) {
    getPropertyChargesListForAgency(input: $input) {
      pagination {
        total
        hasNextPage
      }
      propertyChargesList {
        _id
        property {
          name
          address
        }
        agent {
          name
        }
        createdAt
        cost
        name
      }
    }
  }
`;

export const GET_PENDING_CHARGES = gql`
  query {
    getPendingCharges {
      dueAmount
      hasCardExpired
      nextInvoiceDate
    }
  }
`;
