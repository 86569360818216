import { gql } from "@apollo/client";

export const GET_USER_BANK_ACCOUNT = gql`
  query {
    GetUserBankAccount {
      userBankAccounts {
        _id
        accountHolderName
        last4
        routingNumber
        bankAccountNumber
        isDefault
        stripePaymentMethodId
      }
    }
  }
`;
