import { lazy } from "react";
// project imports
import AuthGuard from "utils/route-guard/AuthGuard";
import AgencyAuthGuard from "utils/route-guard/AgencyAuthGuard";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

// dashboard routing
const Dashboard = Loadable(lazy(() => import("views/dashboard")));

// menu routing
const MenuList = Loadable(lazy(() => import("views/menu/menusList")));
const AddMenu = Loadable(lazy(() => import("views/menu/addMenu")));
const EditMenu = Loadable(lazy(() => import("views/menu/editMenu")));

// User managemenet routing

const AdminProfile = Loadable(lazy(() => import("views/profile")));
const AdminChangePassword = Loadable(
  lazy(() => import("views/profile/change-password"))
);
const UserList = Loadable(lazy(() => import("views/userManagement/users")));
const UserProfile = Loadable(
  lazy(() => import("views/userManagement/users/profile"))
);

const PropertyList = Loadable(lazy(() => import("views/property/index")));
const AddProperty = Loadable(
  lazy(() => import("views/property/add-new-property/index"))
);
const EditProperty = Loadable(
  lazy(() => import("views/property/edit-property/index"))
);
const PropertyDetails = Loadable(
  lazy(() => import("views/property/propertyDetails/index"))
);

//Agents
const EditAgentSettingsForAgency = Loadable(
  lazy(
    () =>
      import(
        "views/userManagement/agency/edit-agent/edit-agent-form/edit-account-settings"
      )
  )
);

const AgencyProfile = Loadable(
  lazy(() => import("views/userManagement/agency/my-agency"))
);

const CreateNewAgentForAgency = Loadable(
  lazy(() => import("views/userManagement/agency/add-new-agent"))
);

// const EditAgentForAgency = Loadable(
//   lazy(() => import("views/userManagement/agency/edit-agent/edit-agent-form"))
// );

const EditAgentDetailsForAgency = Loadable(
  lazy(
    () =>
      import(
        "views/userManagement/agency/edit-agent/edit-agent-form/edit-personal-details"
      )
  )
);

const AgentModuleForAgency = Loadable(
  lazy(() => import("views/userManagement/agency/edit-agent"))
);

// Mock component
const MockComponent = Loadable(lazy(() => import("views/mock")));

//Subscriptions
const Subscription = Loadable(lazy(() => import("views/profile/subscription")));

const CancelSubsription = Loadable(
  lazy(() => import("views/profile/subscription/cancel-subscription"))
);

const BillingHistoryDetail = Loadable(
  lazy(
    () =>
      import(
        "views/profile/subscription/billing-history/billing-history-detail/index"
      )
  )
);

const MyAgentProperties = Loadable(
  lazy(() => import("views/my-properties/index"))
);

const AgentProperties = Loadable(
  lazy(() => import("views/agent-properties/index"))
);

const MyEnquiries = Loadable(
  lazy(() => import("views/my-enquiries-agent/index"))
);

const EnquiryDetail = Loadable(
  lazy(() => import("views/my-enquiries-agent/enquiry/EnquiryDetail"))
);
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "dashboard",
      element: <Dashboard />,
    },

    {
      path: "agency",
      element: <AgencyProfile />,
      name: "AgencyRoute",
    },
    {
      path: "/agency/add-new-agent",
      element: (
        <AgencyAuthGuard>
          <CreateNewAgentForAgency />
        </AgencyAuthGuard>
      ),
    },
    {
      path: "/agency/edit-agent/:id",
      element: <AgentModuleForAgency />,
    },
    {
      path: "/agency/edit-agent/edit-details/:id",
      element: (
        <AgencyAuthGuard>
          <EditAgentDetailsForAgency />
        </AgencyAuthGuard>
      ),
    },
    {
      path: "/agency/edit-agent/edit-settings/:id",
      element: (
        <AgencyAuthGuard>
          <EditAgentSettingsForAgency />
        </AgencyAuthGuard>
      ),
    },
    {
      path: "property/list",
      element: (
        <AuthGuard>
          <PropertyList />
        </AuthGuard>
      ),
    },
    {
      path: "property/add-new-property",
      element: (
        <AuthGuard>
          <AddProperty />
        </AuthGuard>
      ),
    },
    {
      path: "property/edit-property/:id",
      element: (
        <AuthGuard>
          <EditProperty />
        </AuthGuard>
      ),
    },
    {
      path: "property/:id",
      element: <PropertyDetails />,
    },
    {
      path: "/subscription",
      element: <Subscription />,
    },
    {
      path: "menu/list",
      element: <MenuList />,
    },
    {
      path: "menu/add",
      element: <AddMenu />,
    },
    {
      path: "menu/edit/:id",
      element: <EditMenu />,
    },

    {
      path: "user/list",
      element: <UserList />,
    },
    {
      path: "user/profile/:id",
      element: <UserProfile />,
    },
    {
      path: "/profile",
      element: <AdminProfile />,
    },

    {
      path: "admin/change-password",
      element: <AdminChangePassword />,
    },
    {
      path: "mock",
      element: <MockComponent />,
    },
    {
      path: "/subscription/cancel",
      element: <CancelSubsription />,
    },
    {
      path: "/billing/:id",
      element: <BillingHistoryDetail />,
    },
    {
      path: "/myAgency/properties",
      element: <MyAgentProperties />,
    },
    {
      path: "/agent/my-properties",
      element: <AgentProperties />,
    },
    {
      path: "/enquiries",
      element: <MyEnquiries />,
    },
    {
      path: "/enquiries/:id",
      element: <EnquiryDetail />,
    },
  ],
};

export default MainRoutes;
