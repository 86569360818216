import { gql } from "@apollo/client";

export const GET_SUBSCRIPTION_DETAIL = gql`
  query getSubscriptionDetailAgency {
    getSubscriptionDetailAgency {
      subscriptionSetting {
        _id
        agencyId
        commencementDate
        residentialPricing {
          salePost
          rentPost
        }
        commercialPricing {
          salePost
          rentPost
        }
        relistingPricing {
          salePost
          rentPost
          holidayLettings
        }
        fee
        status
        cancel_at
      }
    }
  }
`;

export const GET_SUBSCRIPTION_INFORMATION = gql`
  query getSubscriptionInformationAgency {
    getSubscriptionInformationAgency {
      subscription {
        _id
        startDate
        endDate
        amount
        cancelDate
      }
    }
  }
`;

export const GET_SUB_PROPERTY_DUE = gql`
  query getSubPropertyCardInfo {
    getSubPropertyCardInfo {
      subscription {
        dueAmount
        invoiceDate
      }
      card {
        isExpired
        paymentMethod
      }
      propertyCharge {
        dueAmount
      }
    }
  }
`;
