// third-party
import { FormattedMessage } from "react-intl";

// assets
import PieIcon from "ui-component/icons/statsIcon";
import EnquiryIcon from "ui-component/icons/EnquiryIcon";
import PropertiesIcon from "ui-component/icons/PropertiesIcon";
import AgencyIcon from "ui-component/icons/AgencyIcon";

// constant
const icons = {
  PieIcon,
  AgencyIcon,
  PropertiesIcon,
  EnquiryIcon,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: "sample-docs-roadmap",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      icon: icons.PieIcon,
      url: "/dashboard",
      breadcrumbs: false,
    },
    {
      id: "agency",
      title: <FormattedMessage id="agency" defaultMessage="My Agency" />,
      type: "item",
      hasChildren: true,
      icon: icons.AgencyIcon,
      url: "/agency",
      breadcrumbs: false,
    },
    {
      id: "myAgency",
      title: "My Agency Properties",
      type: "item",
      icon: icons.PropertiesIcon,
      url: "/myAgency/properties",
      breadcrumbs: false,
    },
    {
      id: "agent",
      title: "My Properties",
      type: "item",
      icon: icons.PropertiesIcon,
      url: "/agent/my-properties",
      breadcrumbs: false,
    },

    {
      id: "enquiries",
      title: "My Enquiries",
      type: "item",
      icon: icons.EnquiryIcon,
      url: "/enquiries",
      breadcrumbs: false,
    },
  ],
};

export default other;
