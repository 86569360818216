// third-party
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type order = "asc" | "desc";

type SortType = {
  order: order;
  orderBy: string;
};

type TableType = {
  searchText: string;
  limit: number;
  page: number;
  rowsPerPage: number;
  sort: SortType;
  startDate: string | null;
  endDate: string | null;
};

const initialState: TableType = {
  searchText: "",
  limit: 10,
  page: 0,
  rowsPerPage: 6,
  sort: {
    order: "asc",
    orderBy: "_id",
  },
  startDate: null,
  endDate: null,
};
const slice = createSlice({
  name: "checkins",
  initialState,
  reducers: {
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<number>) {
      state.rowsPerPage = action.payload;
    },
    setSort(state, action: PayloadAction<SortType>) {
      state.sort = action.payload;
    },
    setStartDate(state, action: PayloadAction<string | null>) {
      state.startDate = action.payload;
    },
    setEndDate(state, action: PayloadAction<string | null>) {
      state.endDate = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export const {
  setSearchText,
  setLimit,
  setPage,
  setRowsPerPage,
  setSort,
  setStartDate,
  setEndDate,
} = slice.actions;
